jQuery( function ($) {

    $('.slider_banner').each(function () {
        let slider_speed = 300;
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );
        let $slides = $( '.slick-slide', $slider ).css( 'transition-duration', `${ slider_speed }ms` );
        let $slide_imgs = $( 'img', $slides );
        let $text_wrapper = $( '.text-wrapper', $section );
        let $slider_text = $( '.slider-text', $text_wrapper ).css( 'transition-duration', `${ slider_speed }ms` );
        let $live_section = $( '.slider-text-live', $section );
        let $g_background = $( '.g-background', $section );

        let autoplay_speed = parseInt( $slider.data( 'autoplay-speed' ) );

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: slider_speed,
            arrows: true,
            infinite: false,
            draggable: false,
            prevArrow: $( '.prev-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
            autoplaySpeed: autoplay_speed * 1000,
            autoplay: Boolean( autoplay_speed ),
        });

        function position_slides ( active ) {
            $slides.each( function () {
                let slick_index = $(this).attr( 'data-slick-index' );
                $(this).toggleClass( 'next-slide', slick_index == active + 1 );
                let slides_offset = ( slick_index < active ) ? active : slick_index;
                $(this).css({
                    left: `${ slides_offset * slide_width }px`
                });
            });
        }

        $slider.on( 'beforeChange', function ( e, slick, current, next ) {
            position_slides( next );
            // $slides.filter( `[data-slick-index=${next}]` ).focus();

            $slider_text.removeClass( 'active' );
            let text_height = $slider_text.filter( function () {
                return $(this).attr( 'data-slick-index' ) == next;
            }).height();
            $text_wrapper.height( text_height );

            // if ( !ThemeJS.Media_Queries.is_at_least( 'md' ) ) {
            //     ThemeJS.Functions.scroll_to( $text_wrapper );
            // }
        });
        
        $slider.on( 'afterChange', function ( e, slick, current ) {
            $slider_text.removeClass( 'visible' );
            let $active_text = $slider_text.filter( function () {
                return $(this).attr( 'data-slick-index' ) == current;
            }).addClass( 'visible active' );
            $live_section.html( $( 'h2', $active_text ).html() );
        });


        // start resizing events
        let $slick_track = $( '.slick-track', $section );
        let slide_width;
        let resize_debounce;
        
        function resize_slider () {
            $slide_imgs.css( 'transition-duration', 0 );
            $slides.css({
                position: 'static',
                'transition-duration': 0
            });
            $slick_track.css( 'height', 'auto' ).height( $slick_track.height() );
            slide_width = $slides.first().width();
            $slides.each( function () {
                $(this).css({
                    position: 'absolute',
                    'transition-duration': `${ slider_speed }ms`
                });
            });
            position_slides( $slider.slick('slickCurrentSlide') );
            $slide_imgs.css( 'transition-duration', `${ slider_speed }ms` );
            recalculate_slider_text_height();
            recalculate_g_background_height();
        }

        function recalculate_slider_text_height () {
            let height = 0;
            if ( $(window).width() >= 768 ) {
                $slider_text.each ( function () {
                    let this_height = $(this).height();
                    height = ( this_height > height ) ? this_height : height;
                });
            } else {
                let current_slide = $slider.slick( 'slickCurrentSlide' );
                height = $slider_text.filter( function () {
                    return $(this).attr( 'data-slick-index' ) == current_slide;
                }).height();
            }
            $text_wrapper.height( height );
        }

        function recalculate_g_background_height () {
            let bottom = ThemeJS.Media_Queries.is_at_least( 'md' ) ? 0 : `${ parseInt( $section.css( 'padding-bottom' ) ) + ( $slider.height() / 2 ) }px`;
            $g_background.css( 'bottom', bottom );
        }

        resize_slider();
        $(window).resize( function () {
            clearTimeout( resize_debounce );
            resize_debounce = setTimeout( resize_slider, 100 );
        });
        // end resizing

    });

});