jQuery( function ($) {

    $('.content_with_slider').each(function () {
        let slider_speed = 300;
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );
        let $slides = $( '.slick-slide', $slider ).css( 'transition-duration', `${ slider_speed }ms` );
        let $slide_imgs = $( 'img', $slides );
        let $text_wrapper = $( '.text-wrapper', $section );
        let slide_transition_property = $section.hasClass( 'text-side-left' ) ? 'left' : 'right';
        if ( slide_transition_property == 'right' ) {
            $slider.attr( 'dir', 'rtl' );
        }

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: slider_speed,
            arrows: true,
            infinite: false,
            draggable: false,
            rtl: ( slide_transition_property == 'right' ),
            prevArrow: $( '.prev-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
        });

        function position_slides ( active ) {
            $slides.each( function () {
                let slick_index = $(this).attr( 'data-slick-index' );
                $(this).toggleClass( 'next-slide', slick_index == active + 1 );
                let slides_offset = ( slick_index < active ) ? active : slick_index;
                let slide_css = {};
                slide_css[ slide_transition_property ] = `${ slides_offset * slide_width }px`;
                $(this).css( slide_css );
            });
        }

        $slider.on( 'beforeChange', function ( e, slick, current, next ) {
            position_slides( next );
        });
        


        // start resizing events
        let $slick_track = $( '.slick-track', $section );
        let slide_width;
        let resize_debounce;
        
        function resize_slider () {
            $slide_imgs.css( 'transition-duration', 0 );
            $slides.css({
                position: 'static',
                'transition-duration': 0
            });
            $slick_track.css('height', 'auto' ).height( $slick_track.height() );
            slide_width = $slides.first().width();
            $slides.each( function () {
                let slide_css = {
                    position: 'absolute',
                    'transition-duration': slider_speed
                };
                $(this).css( slide_css );
            });
            position_slides( $slider.slick('slickCurrentSlide') );
            $slide_imgs.css( 'transition-duration', `${ slider_speed }ms` );
        }

        resize_slider();
        $(window).resize( function () {
            clearTimeout( resize_debounce );
            resize_debounce = setTimeout( resize_slider, 100 );
        });
        // end resizing

    });

});