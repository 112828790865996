jQuery( function ($) {

    $('.quote_slider').each(function () {
        let slider_speed = 300;
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: slider_speed,
            arrows: false,
            dots: true,
            infinite: true,
            draggable: true,
            mobileFirst: true,
            adaptiveHeight: true,
        });

    });

});