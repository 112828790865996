jQuery( function ( $ ) {

    $( 'section.featured_pages' ).each( function () {

        let $section = $( this );
        let $columns = $( '.page-link-col', $section );
        let $height_matchers = $( '.height-matcher', $section );

        let layout_type = $section.data().layout_type;

        let description_sections;
        cache_descriptions();

        resize_section();
        $( window ).on( 'breakpoint_changed', resize_section );

        function cache_descriptions () {
            description_sections = {};
            $columns.each( function () {
                let $description_wrap = $( '.description-wrap', this );
                description_sections[ $( this ).attr( 'aria-describedby' ) ] = $description_wrap.html();
                $description_wrap.remove();
            });
        }

        function resize_section () {
            let per_block = ( layout_type != 'standard' && ThemeJS.Media_Queries.is_at_least( 'md' ) ) ? 4 : 1;
            if ( layout_type == '6-block' &&  ThemeJS.Media_Queries.is_at_least( 'xl' ) ) {
                per_block = 6;
            }
            adjust_section_layout( per_block );
        }

        function adjust_section_layout ( per_block ) {
            let resize_args = get_resize_args( per_block );
            let per_row = Math.round( $columns.parent().width() / $columns.width() );
            $columns.each( function () {
                $( '.text-content', this ).remove();
                if ( per_block == 1 ) {
                    $(this).removeClass( 'img-grow' );
                    $(this).append( description_sections[ $(this).attr( 'aria-describedby' ) ] );
                } else {
                    let index = $(this).index();
                    let index_mod = index % per_block;
                    let is_first_row_of_block = index_mod < per_row;
                    let has_next_row = $(this).parent().children().eq( index + per_row ).length;
                    if ( !is_first_row_of_block || !has_next_row ) {
                        $(this).append( description_sections[ $(this).attr( 'aria-describedby' ) ] );
                    } else if ( index_mod != resize_args.content_from_index ) {
                        $( '.height-matcher', this).append( description_sections[ $(this).attr( 'aria-describedby' ) ] );
                    }
                    if ( index_mod == resize_args.content_into_index ) {
                        let $second_column = $(this).parent().children().eq( 1 );
                        $( '.height-matcher', this ).prepend( description_sections[ $second_column.attr( 'aria-describedby' ) ] );
                    }
                    $(this).toggleClass( 'img-grow', resize_args.img_grow_indices.includes( index_mod ) );
                }
                let num_items = $( this ).siblings().length + 1;
                let num_rows = Math.ceil( num_items / per_row );
                let this_row = Math.ceil( ( $( this ).index() + 1 ) / per_row );
                let is_last_row = this_row == num_rows;
                $( 'div.text-content:last-child', this ).toggleClass( 'pb-0', is_last_row );
            });
            $height_matchers.matchHeight();
        }

    });

    function get_resize_args ( block_size ) {
        if ( block_size == 1 ) {
            return false;
        }
        return {
            content_from_index: 1,
            content_into_index: ( block_size == 6 ) ? 4 : 3,
            img_grow_indices: ( block_size == 6 ) ? [ 1, 3, 5 ] : [ 1, 2 ]
        };
    }

});