/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/

ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
        });
    }

    function get_query_parameter( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function scroll_to ( $target, duration = 300 ) {
        if ( $target == '#main-content' ) {
            $target = $( 'main' );
        }
        if ( ! ( $target instanceof jQuery ) ) {
            $target = $( $target );
        }
        if ( !$target.length ) { return; }
        $( 'html, body' ).animate(
            { scrollTop: $target.offset().top - $( 'header.site-header' ).height() - 50 },
            500,
            function() {
                $target.focus();
                if ( $target.is(":focus") ) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                }
            }
        );
    }

    $(document).on( 'click', 'a[href^="#"]', function ( e ) {
        e.preventDefault();
        scroll_to( $(this).attr('href') );
    });

    $( function () {
        // force all external links to open in new tab
        $( "a[href^=http]" ).each( function () {
            if ( this.href.indexOf( location.hostname ) == -1 ) {
                $( this ).attr({
                    target: "_blank",
                    'aria-label': "Opens in a new window"
                });
            }
        })
    });
    
    return {
        send_ajax_request,
        get_query_parameter,
        scroll_to
    }

})( jQuery, this );