jQuery( function ($) {

    let slider_speed = 300;
    let slick_options = {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: slider_speed,
        arrows: false,
        infinite: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
        ]
    };
    // ensure breakpoints in order
    slick_options.responsive.sort( function ( a, b ) {
        return a.breakpoint - b.breakpoint;
    });

    $('.upcoming_events').each(function () {
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );
        let $slides = $( '.slick-slide', $slider );
        let $slider_control_bar = $( '.slider-control-bar', $section );
        let $slider_controls_row = $( '.row', $slider_control_bar );
        let $slider_controls = $([]);
        let $active_page_display = $( '<div>' )
                .addClass( 'active-page-display' )
                .css( 'transition-duration', `${ slider_speed }ms` )
                .appendTo( $slider_control_bar );

        // custom controls
        let resize_debounce;
        init_slider_controls();
        $(window).resize( function () {
            clearTimeout( resize_debounce );
            resize_debounce = setTimeout( init_slider_controls, 10 );
        });

        $slider.slick( slick_options );

        $slider.on( 'beforeChange', function ( e, slick, current, next ) {
            $active_page_display.css({
                left: $slider_controls.filter( `[data-slide=${ next }]` ).position().left
            });
        });

        function init_slider_controls () {
            let num_slides = $slides.length;
            let slides_per_page = slick_options.slidesToScroll;
            let window_size = $(window).width();
            $.each( slick_options.responsive, function ( index, value ) {
                if ( window_size > value.breakpoint && value.settings.hasOwnProperty( 'slidesToScroll' ) ) {
                    slides_per_page = value.settings.slidesToScroll;
                }
            });
            let num_pages = Math.ceil( num_slides / slides_per_page );
            $slider_controls.remove();
            for ( let i = 0; i < num_pages; i++ ) {
                let $control = $( '<a>' )
                    .addClass( 'col slider-control' )
                    .attr( 'href', 'javascript:void(0)' )
                    .attr( 'data-slide', i * slides_per_page )
                    .attr( 'role', 'button' )
                    .html( `<span class='sr-only'>Slide to page ${ i + 1 }</span>` );
                $slider_controls_row.append( $control );
            }
            $slider_controls = $( '.slider-control', $slider_controls_row );
            $active_page_display.css( 'width', `${ $slider_controls.first().width() }px` );
            $slider_controls.click(function () {
                $slider.slick( 'slickGoTo', parseInt( $(this).data().slide ) );
            });
        }

    });

});