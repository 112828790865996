jQuery( function ($) {

    // improved gform ajax spinner
    $(document).on( 'submit', '.gform_wrapper form', function () {
        let $button = $( 'button[type=submit]', this );
        if ( !$button.length ) { return; }
        $button.attr( 'disabled', true );
        $button.attr( 'aria-disabled', 'true' );
        $button.addClass( 'is-ajax-submitting' );
    });


    // add aria-required to date fields
    $( '.gfield_contains_required input.datepicker' ).attr( 'aria-required', 'true' );


    function init_select2 ( element ) {
        let args = {
            minimumResultsForSearch: 5,
            closeOnSelect: $(element).data().close_on_select ?? true,
        };
        if ( $(element).attr( 'placeholder' ) ) {
            args.placeholder = $(element).attr( 'placeholder' );
        }
        $(element).select2( args );
        // disable search field for multiselect -- uses custom checkbox styling
        if ( $(element).attr('multiple') ) {
            $(element).on('select2:opening select2:closing', function( event ) {
                let $searchfield = $(element).parent().find('.select2-search__field');
                $searchfield.prop('disabled', true);
            });
        }
    }

    $( 'select' ).each( function () {
        init_select2( this );
    });

    $(document).on( 'gform_post_render', function ( e, form_id, current_page ) {
        $( 'select', `#gform_${ form_id }` ).each( function () {
            init_select2( this );
        });
    });

});