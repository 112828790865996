jQuery(function ($) {
  const action_banner = $(".action-banner");
  if (!action_banner.length) return;
  $("header").attr("style", "margin-top:" + action_banner.outerHeight() + "px");
  $("main > section:first-child").attr(
    "style",
    "margin-top:" + action_banner.outerHeight() + "px"
  );
  $(window).resize(function () {
    if (!action_banner.hasClass("hidden")) {
      $("header").attr(
        "style",
        "margin-top:" + action_banner.outerHeight() + "px"
      );
      $("main > section:first-child").attr(
        "style",
        "margin-top:" + action_banner.outerHeight() + "px"
      );
    }
  });
  $(window).scroll(function () {
    if ($(window).scrollTop()) {
      $("header").attr("style", "margin-top:0px");
      $("main > section:first-child").attr("style", "margin-top:0px");
    } else {
      if (!action_banner.hasClass("hidden")) {
        $("header").attr(
          "style",
          "margin-top:" + action_banner.outerHeight() + "px"
        );
        $("main > section:first-child").attr(
          "style",
          "margin-top:" + action_banner.outerHeight() + "px"
        );
      }
    }
  });
  $(".close-action-banner").click(function () {
    $("header").attr("style", "margin-top:0px");
    $("main > section:first-child").attr("style", "margin-top:0px");
    let action_banner_text = $(".action-banner span").text();
    action_banner.addClass("hidden");
    ThemeJS.Cookies.setSingle(
      "wordpress_action_banner_opened",
      action_banner_text,
      365
    );
  });
});
