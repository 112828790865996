(function ($) {

    let $header;
    let $body;
    let $menu_wrap;
    let $menu_toggle;
    let $sub_menu_toggles;
    let $window = $(window);

    let transparent_header = false;


    function set_element_refs () {
        $header = $( 'header.site-header' );
        $body = $( document.body );
        $menu_wrap = $( '.menu-wrap', $header );
        $menu_toggle = $( 'button.menu-toggle', $header );
        $sub_menu_toggles = $( '#menu-primary-menu li.menu-item-has-children > a button.sub-menu-toggle', $header );
    }


    function set_opacity () {
        $header.toggleClass( 'opaque', ( !transparent_header || ( $window.scrollTop() != 0 ) ) );
    }


    function init_header_style () {
        // sets initial styles and listeners based on whether header is loaded as fixed
        if ( $header.hasClass( 'fixed' ) ) {
            $header.addClass( 'transparent-at-top' );
            transparent_header = true;
            set_opacity();
            $window.on( 'scroll breakpoint_changed', set_opacity );
        }
        set_body_margin();
        $window.on( 'breakpoint_changed', set_body_margin );
        $header.addClass( 'fixed' );
    }


    function set_body_margin () {
        let spacing = transparent_header ? 0 : $header.height();
        $body.css({
            marginTop: `${ spacing }px`
        });
    }


    function set_menu_tab_index ( tab_index ) {
        tab_index = tab_index ?? ( ThemeJS.Media_Queries.is_at_least( 'xl' ) ? 0 : -1 );
        $( 'a', $menu_wrap ).attr( 'tabindex', tab_index );
        let sub_menu_toggle_tab_index = ( ThemeJS.Media_Queries.is_at_least( 'xl' ) || tab_index == -1 ) ? -1 : 0;
        $( 'button.sub-menu-toggle', $menu_wrap ).attr( 'tabindex', sub_menu_toggle_tab_index );
    }


    $(function () {
        set_element_refs();
        setTimeout( init_header_style, 0 );

        set_menu_tab_index();
        $window.on( 'breakpoint_changed', function () {
            set_menu_tab_index();
        });
        
        $menu_toggle.click( function () {
            $body.toggleClass( 'menu-open' );
            if ( $body.hasClass( 'menu-open' ) ) {
                set_menu_tab_index( 0 );
                $menu_wrap.focus();
            } else {
                set_menu_tab_index( -1 );
            }
        });
        if ( $sub_menu_toggles.length ) {
            $sub_menu_toggles.click( function ( e ) {
                e.preventDefault();
                e.stopPropagation();
                let $parent_li = $(this).closest( 'li' );
                let $submenu = $( 'ul.sub-menu', $parent_li );
                let was_visible = $submenu.is( ':visible' );
                $submenu.slideToggle( 300 );
                $parent_li.attr( 'aria-expanded', !was_visible );
            });
        }

    });

})( jQuery );